import {useState} from "react";
import {PropertyTypology} from "../constants/property.typology.ts";
import searchIcon from "../assets/icons/search-icon.svg";
import provinces from "../assets/json/provincie_italiane.json";
import {useTranslation} from "react-i18next";

export default function OffMarketSearchForm ({onSearch}) {
    const { t } = useTranslation();

    const [country, setCountry] = useState('ITALIA');
    const [province, setProvince] = useState('');
    const [typology, setTypology] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [minPrice, setMinPrice] = useState('');

    const handleMinPriceChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, ""); // Rimuove tutto tranne i numeri
        const formattedValue = new Intl.NumberFormat("it-IT").format(rawValue); // Formatta il numero con separatori italiani
        setMinPrice(formattedValue);
    };

    const handleMaxPriceChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, ""); // Rimuove tutto tranne i numeri
        const formattedValue = new Intl.NumberFormat("it-IT").format(rawValue); // Formatta il numero con separatori italiani
        setMaxPrice(formattedValue);
    };

    const handleKeyDown = (e) => {
        // Blocca l'input di caratteri non numerici (ma consente backspace, delete, etc.)
        if (
            ["e", "E", "+", "-"].includes(e.key) || // Blocca caratteri invalidi
            (e.key === "." && e.target.value.includes(".")) // Blocca più di un punto decimale
        ) {
            e.preventDefault();
        }
    };

    const handleClick = () => {
        const filters ={
            province: province ?? '',
            typology: typology ?? '',
            maxPrice: maxPrice ?? '',
            minPrice: minPrice ?? '',
            status: 'offMarket'
        }
        onSearch(1, filters, true);
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-y-10 xl:gap-y-0 w-full md:gap-x-[40px]">
            <input
                type="text"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder={t('offMarketProperty.searchForm.labels.country')}
                disabled={true}
            />
            <select
                value={province}
                onChange={(e) => setProvince(e.target.value)}
                className="w-full"
            >
                <option value={null}>
                    {t('offMarketProperty.searchForm.labels.location')}
                </option>
                {Object.entries(provinces).map(([key, province]) => (
                    <option key={key} value={province.code}>
                        {province.name}
                    </option>
                ))}
            </select>
            <select className="w-full md:w-auto" value={typology} onChange={(e) => setTypology(e.target.value)}>
                <option value={null}>{t('offMarketProperty.searchForm.labels.typology')}</option>
                {Object.entries(PropertyTypology).map(([key, typology]) => (
                    <option key={key} value={typology}>
                        {t(`property.typologies.${typology}`)}
                    </option>
                ))}
            </select>
            <div className="flex items-end">
                <input
                    type="text"
                    value={minPrice}
                    onChange={handleMinPriceChange}
                    onKeyDown={handleKeyDown}
                    placeholder={t('offMarketProperty.searchForm.labels.minPrice')}
                    className="w-full"
                />
                <span className={'font-raleway text-[18px] md:text-[16px] text-secondary border-b pb-[15px]'}>€</span>
            </div>
            <div className="flex items-end w-full md:w-auto">
                <input
                    type="text"
                    value={maxPrice}
                    onChange={handleMaxPriceChange}
                    onKeyDown={handleKeyDown}
                    placeholder={t('offMarketProperty.searchForm.labels.maxPrice')}
                    className="w-full"
                />
                <span className={'font-raleway text-[18px] md:text-[16px] text-secondary border-b pb-[15px]'}>€</span>
            </div>
            <div className={'xl:grid xl:grid-cols-subgrid xl:col-span-5 xl:mt-[36.5px]'}>
            <button
                    onClick={handleClick}
                    className="btn-form w-full md:!text-[16px] xl:col-start-5"
                >
                <div className="flex justify-between pb-[5px]">
                            <span>{t('offMarketProperty.searchForm.find')}</span>
                            <img src={searchIcon} alt="search"/>
                        </div>
                    </button>
                </div>
            </div>
            );
            }