import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import 'react-phone-number-input/style.css'
import App from "./App";

import './i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);
