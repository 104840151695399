import ContactForm from "../components/ContactForm";
import {useTranslation} from "react-i18next";

export default function PropertyFinding() {
    const {t}= useTranslation();
    return (
        <div className="mt-[200px] md:mt-[283px] px-[40px] md:px-[400px] pb-[150px] flex flex-col items-center">
            <h1 className="h1-primary text-center">
                {t('propertyFinding.title_1')} <i>{t('propertyFinding.title_2')}</i>
            </h1>
            <p className="mt-[60px] text-center">
                {t('propertyFinding.text_1')}<br/>
            </p>
            <div className="mt-[125px]">
                <ContactForm subject={'Consulenza d’acquisto'} />
            </div>
        </div>
    )
}