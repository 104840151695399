import {useRef, useState} from "react";
import shareIcon from "../assets/icons/share-icon.svg";
import Slider from "react-slick";
import carouselLeftArrow from "../assets/icons/carousel-left-arrow.svg";
import carouselRightArrow from "../assets/icons/carousel-right-arrow.svg";
import {useTranslation} from "react-i18next";

export default function PropertySectionTab({description, area, outdoorArea, price, city, reference, floorPlans, propertyTypology, tabLeft = false}) {
    const sliderRef = useRef(null);
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState("property");

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                url: window.location.href,
            })
                .then()
                .catch((error) => console.error('Errore durante la condivisione:', error));
        } else {
            console.log('La condivisione non è supportata in questo browser.');
        }
    };

    const options = [
        { value: "property", label: "properties.tabs.labels.property" },
        { value: "location", label: "properties.tabs.labels.location" },
        { value: "floorPlans", label: "properties.tabs.labels.floorPlans" },
    ];

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
        slidesPerRow: 1,
    };

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const Tab = ({label, active, onClick, addClass}) => (
        <button
            className={`font-raleway text-gray uppercase tracking-[1.8px] ${addClass}
      ${active ? '!font-bold' : '!hover:font-bold'}`}
            onClick={onClick}
        >
            {t(label)}
        </button>
    );

    const Tabs = ({tabs}) => {
        const [activeTab, setActiveTab] = useState(tabs[0].label);
        return (
            <div className="mt-[120px] px-10 xl:px-[298px]">
                <div className={`mb-20 flex ${tabLeft ? 'justify-start' : 'justify-end'}`}>
                    {tabs?.map((tab) => (
                        <Tab
                            key={tab.label}
                            label={tab.label}
                            active={activeTab === tab.label}
                            addClass={tab.addClass}
                            onClick={() => setActiveTab(tab.label)}
                        />
                    ))}
                </div>
                <div className="mt-4">
                    {tabs.find((tab) => tab.label === activeTab)?.content}
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="hidden md:block">
                <Tabs
                    tabs={[
                        {
                            label: 'properties.tabs.labels.property',
                            content:
                                <p className="max-w-[985px] font-raleway text-[18px] tracking-[0] text-gray font-normal">
                                    {description}
                                </p>,
                            addClass: 'me-10'
                        },
                        {label: 'properties.tabs.labels.location', content:
                                <p className="max-w-[985px] font-raleway text-[18px] tracking-[0] text-gray font-normal">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                                    eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                                    takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                                    consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                                    dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                                    dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                                    ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                    diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                    voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                                    gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
                                    sit amet, consetetur sadipscing
                                </p>,
                            addClass: 'me-10'
                        },
                        {
                            label: 'properties.tabs.labels.floorPlans',
                            content: floorPlans?.length === 0
                                ?
                                <div>
                                    <h4 className={'h4-primary'}>
                                        {t('properties.noFloorPlans')}
                                    </h4>
                                </div>
                                :
                                <div>
                                    <Slider {...settings} ref={sliderRef}>
                                        {
                                            floorPlans?.map((item, index) => {
                                                return (
                                                    <div key={index}
                                                         className={`relative group md:p-[15px]`}>
                                                        <img src={item.path}
                                                             alt={`item-${index}`}
                                                             className="w-full h-[560px] md:h-[300px] lg:h-[500px] 2xl:h-[600px] object-cover"
                                                        />
                                                    </div>
                                                );
                                            })
                                        }
                                    </Slider>
                                    <div className="flex justify-center mt-4">
                                        <button
                                            onClick={prevSlide}
                                            className="me-[10px]"
                                        >
                                            <img src={carouselLeftArrow} alt="prevSlide"/>
                                        </button>
                                        <button
                                            onClick={nextSlide}
                                        >
                                            <img src={carouselRightArrow} alt="nextSlide"/>
                                        </button>
                                    </div>
                                </div>
                        },
                    ]}
                />
                <div className="flex w-100 mt-[50px] px-10 xl:px-[298px]">
                    <div className="flex flex-col me-32">
                                <span className="font-raleway text-[18px] tracking-[1.8px] text-gray uppercase">
                                    {t('properties.tabs.labels.area')}
                                </span>
                        <h4 className="h4-primary text-nowrap">
                            {area ? `${area} mq` : '-'}
                        </h4>
                    </div>
                    <div className="flex flex-col me-32">
                                <span className="font-raleway text-[18px] tracking-[1.8px] text-gray uppercase">
                                    {t('properties.tabs.labels.outdoorArea')}
                                </span>
                        <h4 className="h4-primary text-nowrap">
                            {outdoorArea ? `${outdoorArea} mq` : '-'}
                        </h4>
                    </div>
                    <div className="flex flex-col">
                                <span className="font-raleway text-[18px] tracking-[1.8px] text-gray uppercase">
                                    {t('properties.tabs.labels.price')}
                                </span>
                        <h4 className="h4-primary text-nowrap">
                            {price ? `${price} €` : '-'}
                        </h4>
                    </div>
                </div>
            </div>
            <div className="md:hidden px-10 flex flex-col">
                <div className="flex w-100 justify-end">
                    <img className="ms-4 mt-1" src={shareIcon} alt="share" onClick={handleShare}/>
                </div>
                <div className="flex flex-col mt-[18px]">
                    <span className={'font-serifDisplay text-[35px] text-lightGray capitalize'}>
                        {t(`property.typologies.${propertyTypology}`)}
                    </span>
                    <span
                        className="font-raleway text-left uppercase text-gray font-light tracking-[1.8px] w-full pb-10">
                            {city ?? ''}
                    </span>
                    <span
                        className="font-raleway text-left uppercase text-gray font-light tracking-[1.8px] w-full">
                            {reference}
                    </span>
                </div>
                <div className="mt-[68px] grid grid-cols-2 gap-[85px]">
                    <div className="flex flex-col">
                        <span className="font-raleway text-[16px] tracking-[1.6px] uppercase text-lightGray">
                            {t('properties.tabs.labels.area')}
                        </span>
                        <h4 className="h4-primary mt-[5px] !lowercase">
                            {area ? `${area} mq` : '-'}
                        </h4>
                    </div>
                    <div className="flex flex-col">
                        <span className="font-raleway text-[16px] tracking-[1.6px] uppercase text-lightGray">
                            {t('properties.tabs.labels.outdoorArea')}
                        </span>
                        <h4 className="h4-primary mt-[5px] !lowercase">
                            {outdoorArea ? `${outdoorArea} mq` : '-'}
                        </h4>
                    </div>
                    <div className="flex flex-col">
                        <span className="font-raleway text-[16px] tracking-[1.6px] uppercase text-lightGray">
                            {t('properties.tabs.labels.price')}
                        </span>
                        <h4 className="h4-primary mt-[5px] !lowercase">
                            {price ? `${price} €` : '-'}
                        </h4>
                    </div>
                </div>
            </div>
            <div className="md:hidden px-10 flex flex-col mt-[68px]">
                <select
                    id="dynamic-select"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="w-full"
                >
                    {options?.map((option) => (
                        <option key={option.value} value={option.value}>
                            {t(option.label)}
                        </option>
                    ))}
                </select>
                <div className="mt-[60px]">
                    {selectedOption === "property" && (
                        <p className="max-w-[985px] font-raleway text-[18px] tracking-[0] text-gray font-normal">
                            {description}
                        </p>
                    )}
                    {selectedOption === "location" && (
                        <p className="max-w-[985px] font-raleway text-[18px] tracking-[0] text-gray font-normal">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                            eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                            takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                            ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                            gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
                            sit amet, consetetur sadipscing
                        </p>
                    )}
                    {selectedOption === "floorPlans" && floorPlans?.length> 0 && (
                        <div>
                            <Slider {...settings} ref={sliderRef}>
                                {
                                    floorPlans?.map((item, index) => {
                                        return (
                                            <div key={index}
                                                 className={`relative group md:p-[15px]`}>
                                                <img src={item.path}
                                                     alt={`item-${index}`}
                                                     className="w-full h-[560px] md:h-[300px] lg:h-[500px] 2xl:h-[600px] object-cover"
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </Slider>
                            <div className="flex justify-center mt-4">
                                <button
                                    onClick={prevSlide}
                                    className="me-[10px]"
                                >
                                    <img src={carouselLeftArrow} alt="prevSlide"/>
                                </button>
                                <button
                                    onClick={nextSlide}
                                >
                                    <img src={carouselRightArrow} alt="nextSlide"/>
                                </button>
                            </div>
                        </div>
                    )}
                    {selectedOption === "floorPlans" && floorPlans?.length === 0 && (
                        <h6 className={'h6-primary text-center'}>
                            {t('properties.noFloorPlans')}
                        </h6>
                    )}
                </div>
            </div>
        </div>
    )
}
