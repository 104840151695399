import ContactForm from "../components/ContactForm";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

export default function WorkWithUs() {
    const {t}= useTranslation();
    return (
        <div>
            <Helmet>
                <meta name="keywords" content="posizioni aperte"/>
                <title>Downtown – Posizioni aperte</title>
                <meta name="description"
                      content="Pensi di avere competenza ed esperienza per entrare nel team di Downtown? Inviaci la tua candidatura e la valuteremo con piacere."/>
            </Helmet>
            <div className="mt-[200px] md:mt-[283px] px-[40px] md:px-[400px] pb-[150px] flex flex-col items-center">
                <h1 className="h1-primary">
                    {t('workWithUs.title_1')} <i>{t('workWithUs.title_2')}</i>
                </h1>
                <p className="mt-[60px] text-center md:text-left">
                    {t('workWithUs.text_1_1')}
                    <br/>
                    {t('workWithUs.text_1_2')}
                </p>
                <div className="mt-[125px]">
                    <ContactForm subject={'Richiesta di lavoro'} enableFileUpload={true}/>
                </div>
            </div>
        </div>
    )
}