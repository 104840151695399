import {useRef} from "react";
import Slider from "react-slick";

import carouselLeftArrow from "../assets/icons/carousel-left-arrow.svg";
import carouselRightArrow from "../assets/icons/carousel-right-arrow.svg";
import {useTranslation} from "react-i18next";

export default function PropertySectionGallery({ photos }) {
    const sliderRef = useRef(null);
    const {t} = useTranslation();

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="hidden md:block mt-[120px] text-center">
            <h3 className="h3 mb-[120px]">{t('properties.gallery')}</h3>
            <div className="relative">
                <Slider ref={sliderRef} {...settings}>
                    {photos.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="w-1/3 h-[600px] px-[8px]"
                            >
                                <img
                                    src={item.path}
                                    alt={`item-${index}`}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        );
                    })}
                </Slider>
                <div className="mt-10 flex justify-end">
                    <div className="w-1/3 text-start ps-[15px]">
                        <button
                            onClick={prevSlide}
                            className="me-[10px]"
                        >
                            <img src={carouselLeftArrow} alt="prevSlide" />
                        </button>
                        <button
                            onClick={nextSlide}
                        >
                            <img src={carouselRightArrow} alt="nextSlide" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
