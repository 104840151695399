import searchIcon from "../assets/icons/search-icon.svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function NotFound() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleClick = () => {
        navigate('/');
    }

    return (
        <div className="flex flex-col items-center pt-[150px] md:pt-[335px] pb-[100px] md:pb-[151px] text-center px-10">
            <span className="font-serifDisplay text-[150px] md:text-[238px] tracking-0">
                {t('notFound.code')}
            </span>
            <span className="title mt-[-130px] md:mt-[-150px] !text-[40px]">
                {t('notFound.title')}
            </span>
            <button type="submit" className="btn-form mt-32 md:mt-11 w-full md:w-1/4 ps-[42px] md:ps-0" onClick={handleClick}>
                <div className="flex justify-between">
                    <span className="me-8">
                        {t('notFound.backToHome')}
                    </span>
                    <img src={searchIcon} alt="search"/>
                </div>
            </button>
        </div>
    )
}