import logoElement from 'assets/images/company/logo-element.svg'
import { motion } from "motion/react"
import {useTranslation} from "react-i18next";

export default function WhoWeAre () {
    const { t } = useTranslation();
    return (
        <div>
            <div className="hidden lg:block absolute left-0">
                <img src={logoElement} alt="Logo element"/>
            </div>
            <div className="bg-primary px-10 md:pt-[120px] lg:pe-[80px] lg:ps-[500px] 2xl:pe-[300px] 2xl:ps-[600px] flex justify-end mt-20 mb-[90px] md:mt-auto md:mb-[120] z-10">
                <motion.p
                    initial={{ x: "100%", opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                    viewport={{ once: true}}
                    className="text-left">
                    {t('whoWeAre.text_1_1')}
                    <br/><br/>
                    {t('whoWeAre.text_1_2')}
                    <br/><br/>
                    {t('whoWeAre.text_1_3')}
                </motion.p>
            </div>
        </div>
    )
}