import {BrowserRouter, Route, Routes} from "react-router-dom";

import Home from "./pages/Home";
import Properties from "./pages/Properties";
import Property from "./pages/Property";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Company from "./pages/Company";
import Contacts from "./pages/Contacts";
import NotFound from "./pages/NotFound";
import ThankYou from "./pages/ThankYou";
import WorkWithUs from "./pages/WorkWithUs";
import PropertyFinding from "./pages/PropertyFinding";
import OffMarketProperties from "./pages/OffMarketProperties";
import OffMarketProperty from "./pages/OffMarketProperty";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
      <BrowserRouter>
          <ScrollToTop />
          <div className="flex flex-col min-h-screen overflow-x-hidden">
              <Header />
              <main className="flex-grow">
                  <Routes>
                      <Route path="/" element={<Home />}/>
                      <Route path="/properties" element={<Properties />}/>
                      <Route path="/property/:propertyId" element={<Property />}/>
                      <Route path="/about-us" element={<Company />}/>
                      <Route path="/contacts" element={<Contacts />}/>
                      <Route path="/thank-you" element={<ThankYou />}/>
                      <Route path="/downtown_posizioni_aperte" element={<WorkWithUs />}/>
                      <Route path="/property-finding" element={<PropertyFinding />}/>
                      <Route path="/off-market-properties" element={<OffMarketProperties />}/>
                      <Route path="/off-market-property/:propertyId" element={<OffMarketProperty />}/>
                      <Route path="*" element={<NotFound />} />
                  </Routes>
              </main>
              <Footer />
          </div>
      </BrowserRouter>
);
}

export default App;
