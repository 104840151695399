import {useEffect, useState} from "react";
import searchIcon from "../assets/icons/search-icon.svg";
import uploadIcon from "../assets/icons/upload-icon.svg";
import PhoneInput from 'react-phone-number-input'
import it from 'react-phone-number-input/locale/it'
import {useTranslation} from "react-i18next";


interface ContactFormProps {
    enableFileUpload?: boolean;
    subject: string
}

export default function ContactForm({ enableFileUpload = false, subject }: ContactFormProps) {
    const [fileName, setFileName] = useState("");
    const [notification, setNotification] = useState(null);
    const url = process.env.REACT_APP_API_URL;
    const { t } = useTranslation();


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain', 'application/rtf'];
            if (!allowedTypes.includes(file.type)) {
                alert(t('contactForm.alerts.type'));
                return;
            }

            const maxSize = 5 * 1024 * 1024;
            if (file.size > maxSize) {
                alert(t('contactForm.alerts.size'));
                return;
            }

            setFileName(file.name);
            setFormData({
                ...formData,
                file: file
            });
        }
    };

    const handleClick = () => {
        document.getElementById("fileInput").click();
    };

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: '',
        email: '',
        subject: subject,
        phone: '',
        language: '',
        country: '',
        message: '',
        checkbox: false,
        file: null,
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (formData.phone) {
            const event = {
                target: {
                    nome: 'phone',
                    value: formData.phone,
                    type: 'text',
                    checked: false,
                    files: null,
                },
            };
            handleChange(event);
        }
    }, [formData.phone]);

    const handlePhoneChange = (phoneNumber) => {
        if (phoneNumber) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                phone: phoneNumber,
            }));
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type, checked, files } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : type === 'file' ? files?.[0] || null : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length === 0) {
            const formDataToSend = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                if (value instanceof File) {
                    formDataToSend.append(key, value);
                } else {
                    formDataToSend.append(key, String(value));
                }
            });

            formDataToSend.delete('checkbox')

            sendFormData(formDataToSend);
        } else {
            setErrors(validationErrors);
        }
    };


    const sendFormData = async (formDataToSend) => {
        try {
            const response = await fetch(`${url}/public-apis/contact`, {
                method: "POST",
                body: formDataToSend,
            });

            if (!response.ok) {
                throw new Error(t('contactForm.alerts.error'));
            }

            setNotification({ message: t('contactForm.alerts.messageSent'), type: "success" });
        } catch (error) {
            console.error(t('contactForm.alerts.errorApi'), error);
            setNotification({ message: t('contactForm.alerts.generalError'), type: "error" });
        }
    };


    const validateForm = () => {
        setErrors({});
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = "contactForm.validators.firstName";
        if (!formData.lastName) newErrors.lastName = "contactForm.validators.lastName";
        if (!formData.email) newErrors.email = "contactForm.validators.email";
        if (!formData.phone) newErrors.phone = "contactForm.validators.phone";
        if (!formData.language) newErrors.language = "contactForm.validators.language";
        if (!formData.country) newErrors.country = "contactForm.validators.country";
        if (!formData.message) newErrors.message = "contactForm.validators.message";
        if (!formData.checkbox) newErrors.checkbox = "contactForm.validators.checkbox";
        return newErrors;
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-10 gap-x-5 mb-10">
                    <div className="flex flex-col">
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder={t('contactForm.labels.firstName')}
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                        {errors.firstName && <p className="text-left text-red-500">{t(errors.firstName)}</p>}
                    </div>
                    <div className="flex flex-col">
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder={t('contactForm.labels.lastName')}
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                        {errors.lastName && <p className="text-left text-red-500">{t(errors.lastName)}</p>}
                    </div>
                    <div className="flex flex-col">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder={t('contactForm.labels.email')}
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {errors.email && <p className="text-left text-red-500">{t(errors.email)}</p>}
                    </div>
                    <div className="flex flex-col">
                        <PhoneInput
                            id="phone"
                            name="phone"
                            placeholder={t('contactForm.labels.phone')}
                            value={formData.phone}
                            defaultCountry="IT"
                            labels={it}
                            international
                            onChange={handlePhoneChange}/>
                        {errors.phone && <p className="text-left text-red-500">{t(errors.phone)}</p>}
                    </div>
                    <div className="flex flex-col">
                        <select
                            id="language"
                            name="language"
                            value={formData.language}
                            className={'w-full'}
                            onChange={handleChange}
                        >
                            <option value=''>
                                {t('contactForm.labels.language')}
                            </option>
                            <option value={'italiano'}>
                                {t('contactForm.languages.italian')}
                            </option>
                            <option value={'inglese'}>
                                {t('contactForm.languages.english')}
                            </option>
                        </select>
                        {errors.language && <p className="text-left text-red-500">{t(errors.language)}</p>}
                    </div>
                    <div className="flex flex-col">
                        <input
                            id="country"
                            name="country"
                            placeholder={t('contactForm.labels.country')}
                            value={formData.country}
                            onChange={handleChange}
                        >
                        </input>
                        {errors.country && <p className="text-left text-red-500">{t(errors.country)}</p>}
                    </div>
                    {enableFileUpload && (
                        <div>
                            <input
                                type="file"
                                id="fileInput"
                                className="hidden"
                                accept=".pdf,.doc,.docx,.txt,.rtf"
                                onChange={handleFileChange}
                            />
                            <div onClick={handleClick}
                                className="cursor-pointer text-secondary uppercase font-raleway text-[18px] tracking-[1.8px] border-b flex justify-between pb-[15px]">
                                <span>
                                    {fileName || t('contactForm.labels.curriculum')}
                                </span>
                                <img src={uploadIcon} alt="upload file"/>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex flex-col mb-10">
                        <textarea
                            id="message"
                            name="message"
                            placeholder={t('contactForm.labels.message')}
                            value={formData.message}
                            onChange={handleChange}
                            className="w-full"
                            rows="5"
                        ></textarea>
                    {errors.message && <p className="text-left text-red-500">{t(errors.message)}</p>}
                </div>
                <div className="flex flex-col mb-7">
                    <div className="mt-[30px] text-left flex items-start">
                        <input
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            checked={formData.checkbox}
                            onChange={handleChange}
                            className="mt-1 me-3"
                        />
                        <span className="font-raleway font-normal test-[14px] tracking-[0] text-lightGray">
                            {t('contactForm.disclaimer')}
                        </span>
                    </div>
                    {errors.checkbox && <p className="text-left text-red-500">{t(errors.checkbox)}</p>}
                </div>
                <div className="flex justify-end">
                    <button type="submit" className="btn-form mt-[30px] text-end w-full md:w-1/2 ps-[15px] md:ps-0">
                        <div className="flex justify-between">
                            <span className="me-8">
                                {t('contactForm.send')}
                            </span>
                            <img src={searchIcon} alt="search"/>
                        </div>
                    </button>
                </div>
            </form>
            {notification && (
                <div
                    className={`mb-4 p-4 mt-10 ${
                        notification.type === "success" ? "text-lightGray font-raleway text-[18px]" : "text-red-500"
                    }`}
                >
                    {notification.message}
                </div>
            )}
        </div>
    )
}