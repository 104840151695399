import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import shareIcon from "../assets/icons/share-icon.svg";
import PropertySectionTab from "../components/PropertySectionTab";
import PropertySectionContact from "../components/PropertySectionContact";
import {useTranslation} from "react-i18next";

export default function OffMarketProperty() {
    const {propertyId} = useParams();
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);
    const url = process.env.REACT_APP_API_URL;
    const { t } = useTranslation();

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                url: window.location.href,
            })
                .then()
                .catch((error) => console.error('Errore durante la condivisione:', error));
        } else {
            console.log('La condivisione non è supportata in questo browser.');
        }
    };

    useEffect(() => {
        async function getProperty() {
            const response = await fetch(`${url}/public-apis/property/${propertyId}`);
            const resData = await response.json();
            setProperty(resData);
            setLoading(false);
        }
        getProperty();
    }, [propertyId, url]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="relative h-full flex items-end mt-[200px] md:mt-[355px]">
                <div className="w-full px-10 xl:px-[300px] hidden md:block">
                    <div className="flex justify-between items-end">
                        <div className="flex flex-col">
                            <h3 className={'h3'}>
                                {t(`property.typologies.${property?.propertyTypology}`)}
                            </h3>
                            <span
                                className="font-raleway text-left text-[18px] tracking-[1.8px] text-gray uppercase">{property?.city}</span>
                        </div>
                        <div className="flex">
                            <span
                                className={'font-raleway text-left text-[18px] tracking-[1.8px] text-gray uppercase flex'}>{property?.reference}
                            </span>
                            <button
                                className="font-raleway text-left text-[18px] tracking-[1.8px] text-gray uppercase flex" onClick={handleShare}>
                                <img className="ms-4 mt-1" src={shareIcon} alt="share"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <PropertySectionTab
                description={property?.propertyDescription}
                area={property?.area.toLocaleString('it-IT')}
                outdoorArea={property?.outdoorArea.toLocaleString('it-IT')}
                price={property?.price.toLocaleString('it-IT')}
                city={property?.city}
                reference={property?.reference}
                floorPlans={property?.floorPlans}
                propertyTypology={property?.propertyTypology}
            />
            <PropertySectionContact reference={property?.reference} />
        </div>
    )
}