import React, {useRef} from "react";
import bgImage from "../assets/images/company/i-nostri-pilastri-img-bg.jpg";
import logoIconBullet from "../assets/icons/logo-icon-bullet.svg";
import Slider from "react-slick";
import carouselLeftArrow from "../assets/icons/carousel-left-arrow.svg";
import carouselRightArrow from "../assets/icons/carousel-right-arrow.svg";
import { motion } from "motion/react"
import {useTranslation} from "react-i18next";


export default function OurPillars() {
    const { t } = useTranslation();
    const sliderRef = useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        rows: 1,
        slidesPerRow: 1,
        slidesToScroll: 1,
    };

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <div
            className="bg-cover bg-center bg-no-repeat h-full"
            style={{backgroundImage: `url('${bgImage}')`}}
        >
            <div className="relative flex flex-col h-full bg-primary bg-opacity-90">
                <div className="px-10 lg:px-[60px] 2xl:px-[300px]">
                    <h3 className="h3 mt-[90px] md:mt-[156px]">
                        {t('ourPillars.ours')} <i>{t('ourPillars.pillars')}</i>
                    </h3>
                    <div className="hidden md:grid grid-cols-3 gap-5 mt-[55px] mb-[92px]">
                        <div className="bg-[#323232] opacity-90 h-full px-[38px] py-[88px] flex flex-col">
                            <img width="70" height="auto" src={logoIconBullet} alt="Logo Icon Bullet"/>
                            <span
                                className="font-raleway !font-bold text-[20px] tracking-[2px] mt-[42.7px] uppercase text-lightGray">
                                {t('ourPillars.exclusive')}
                            </span>
                            <p className="mt-[39px]">
                                {t('ourPillars.text_1_1')}<br/><br/>{t('ourPillars.text_1_2')}
                            </p>
                        </div>
                        <motion.div
                            initial={{ x: "100%", opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ duration: 0.8, ease: "easeOut" }}
                            viewport={{ once: true}}
                            className="bg-[#323232] opacity-90 h-full px-[38px] py-[88px] flex flex-col">
                            <img width="70" height="auto" src={logoIconBullet} alt="Logo Icon Bullet"/>
                            <span
                                className="font-raleway !font-bold text-[20px] tracking-[2px] mt-[42.7px] uppercase text-lightGray">
                                {t('ourPillars.network')}
                            </span>
                            <p className="mt-[39px]">
                                {t('ourPillars.text_2_1')}<br/><br/>{t('ourPillars.text_2_2')}
                            </p>
                        </motion.div>
                        <motion.div
                            initial={{ x: "100%", opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ duration: 0.8, ease: "easeOut", delay: 0.7 }}
                            viewport={{ once: true}}
                            className="bg-[#323232] opacity-90 h-full px-[38px] py-[88px] flex flex-col">
                            <img width="70" height="auto" src={logoIconBullet} alt="Logo Icon Bullet"/>
                            <span
                                className="font-raleway !font-bold text-[20px] tracking-[2px] mt-[42.7px] uppercase text-lightGray">
                                {t('ourPillars.personalAdvisor')}
                            </span>
                            <p className="mt-[39px]">
                                {t('ourPillars.text_3_1')}<br/><br/>{t('ourPillars.text_3_2')}
                            </p>
                        </motion.div>
                    </div>
                    <div className="md:hidden mt-[63px]">
                        <Slider {...settings} ref={sliderRef}>
                            <div className="h-full flex flex-col">
                                <img className="mb-[43px]" width="90" height="159" src={logoIconBullet} alt="Logo Icon Bullet"/>
                                <span
                                    className="font-raleway font-bold text-[16px] md:text-[20px] tracking-[2px]uppercase text-lightGray mt-">
                                    {t('ourPillars.exclusive')}
                                </span>
                                <p className="mt-[39px]">
                                    {t('ourPillars.text_1_1')}<br/><br/>{t('ourPillars.text_1_2')}
                                </p>
                            </div>
                            <div className="h-full flex flex-col">
                                <img className="mb-[43px]" width="90" height="159" src={logoIconBullet} alt="Logo Icon Bullet"/>
                                <span
                                    className="font-raleway font-bold text-[16px] md:text-[20px] tracking-[2px] mt-[42.7px] uppercase text-lightGray">
                                    {t('ourPillars.network')}
                                </span>
                                <p className="mt-[39px]">
                                    {t('ourPillars.text_2_1')}<br/><br/>{t('ourPillars.text_2_2')}
                                </p>
                            </div>
                            <div className="h-full flex flex-col">
                                <img className="mb-[43px]" width="90" height="159" src={logoIconBullet} alt="Logo Icon Bullet"/>
                                <span
                                    className="font-raleway font-bold text-[16px] md:text-[20px] tracking-[2px] mt-[42.7px] uppercase text-lightGray">
                                {t('ourPillars.personalAdvisor')}
                            </span>
                                <p className="mt-[39px]">
                                    {t('ourPillars.text_3_1')}<br/><br/>{t('ourPillars.text_3_2')}
                                </p>
                            </div>
                        </Slider>
                        <div className="mt-[70px] mb-[90px]">
                            <div className="flex justify-center">
                                <button
                                    onClick={prevSlide}
                                    className="me-[10px]"
                                >
                                    <img src={carouselLeftArrow} alt="prevSlide"/>
                                </button>
                                <button
                                    onClick={nextSlide}
                                >
                                    <img src={carouselRightArrow} alt="nextSlide"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}