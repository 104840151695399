import logoFooter from '../assets/logos/logo-footer-cutted.svg'
import logoFooterMobile from '../assets/logos/logo-footer-mobile.svg'
import searchIcon from "../assets/icons/search-icon.svg";
import {Link, useNavigate} from "react-router-dom";
import { motion } from "motion/react"
import {useTranslation} from "react-i18next";

export default function Footer() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const onContactsClick = () => {
        navigate("/contacts");
    }

    const onWorkWithUsClick = () => {
        navigate("/downtown_posizioni_aperte");
    }

    const currentYear = () => {
        return new Date().getFullYear();
    };

    return (
        <footer>
            <div className="pt-[100px] md:pt-[87px] flex flex-col">
                <div className="md:hidden pb-[40px]">
                    <div className="flex flex-col px-[40px]">
                        <h4 className="h4-secondary !text-wrap">
                            {t('footer.personalAdvisor')}
                        </h4>
                        <button
                            className="btn-form mt-10"
                            onClick={onContactsClick}
                        >
                            <div className="flex justify-between">
                                <span className="font-light">
                                    {t('footer.contactUs')}
                                </span>
                                <img src={searchIcon} alt="search"/>
                            </div>
                        </button>
                        <div className="flex flex-col mt-[60px]">
                            <h4 className="h4-secondary">
                                roma
                            </h4>
                            <a href="mailto:roma@downtown.it" className="h6-secondary mt-[22px]">
                                roma@downtown.it
                            </a>
                        </div>
                        <div className="flex flex-col mt-[60px]">
                            <h4 className="h4-secondary">
                                milano
                            </h4>
                            <a href="mailto:milano@downtown.it" className="h6-secondary mt-[22px]">
                                milano@downtown.it
                            </a>
                        </div>
                        <h6 className="h6-secondary mt-[60px]">
                            instagram | facebook
                        </h6>
                    </div>
                    <img className="mt-[60px] w-full" src={logoFooterMobile} alt="Logo Footer"/>
                    <div className="flex flex-col mt-[40px] px-[40px] text-left">
                        <h6 className="h6-secondary">
                            {t('footer.copyright', {year: currentYear()} )}
                        </h6>
                        <h6 className="h6-secondary mt-[23px]">
                            Credits: <Link to={'https://goonitalia.it/'}>Goon</Link>
                        </h6>
                    </div>
                </div>
                <div className="hidden md:block mb-[50px] md:px-[40px] lg:px-[80px] xl:px-[152px] 2xl:px-[305px]">
                    <div className="grid grid-cols-4 gap-x-10 w-full">
                        <div className="flex flex-col w-full">
                            <span
                                className="font-raleway font-bold md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px] tracking-[1.8px] text-secondary uppercase">
                                roma
                            </span>
                            <a href="mailto:roma@downtown.it" className="h6-secondary mt-[23px]">
                                roma@downtown.it
                            </a>
                        </div>
                        <div className="flex flex-col w-full">
                            <span
                                className="font-raleway font-bold md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px] tracking-[1.8px] text-secondary uppercase">
                                milano
                            </span>
                            <a href="mailto:milano@downtown.it" className="h6-secondary mt-[23px]">
                                milano@downtown.it
                            </a>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 mt-[71px] gap-x-10 w-full">
                        <div className="flex items-start w-full">
                            <h4 className="h4-secondary text-wrap">
                                {t('footer.personalAdvisor')}
                            </h4>
                        </div>
                        <div className="flex items-start">
                            <button
                                className="btn-form w-full"
                                onClick={onContactsClick}
                            >
                                <div className="flex justify-between">
                                    <span>{t('footer.contactUs')}</span>
                                    <img src={searchIcon} alt="search"/>
                                </div>
                            </button>
                        </div>
                        <div className="flex items-start">
                            <button
                                className="btn-form w-full"
                                onClick={onWorkWithUsClick}
                            >
                                <div className="flex justify-between">
                                    <span>{t('footer.workWithUs')}</span>
                                    <img src={searchIcon} alt="search"/>
                                </div>
                            </button>
                        </div>
                        <div className="flex items-start">
                            <h6 className="h6-secondary mb-[47px]">
                                <Link
                                    to={'https://www.instagram.com/downtown_luxuryrealestate/'}>instagram</Link> | <Link
                                to={'https://www.facebook.com/downtownluxuryrealestate'}>facebook</Link>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden md:block overflow-hidden">
                <motion.img
                    initial={{ y: "100%", opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut"}}
                    viewport={{ once: true}}
                    src={logoFooter} alt="Logo Footer"/>
                <div className="h-[67px] bg-darkGray px-10 md:px-[150px] 2xl:px-[305px] flex justify-between items-center">
                    <h6 className="h6-secondary">
                        {t('footer.copyright', {year: currentYear()} )}
                    </h6>
                    <h6 className="h6-secondary">
                        Credits: <Link to={'https://goonitalia.it/'}>Goon</Link>
                    </h6>
                </div>
            </div>
        </footer>
    )
        ;
}
