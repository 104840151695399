import {useEffect, useRef, useState} from "react";
import { motion } from "motion/react"
import searchIcon from "../assets/icons/search-icon.svg";
import logoElement from "../assets/images/logo-element.svg"
import Slider from "react-slick";
import carouselLeftArrow from "../assets/icons/carousel-left-arrow.svg";
import carouselRightArrow from "../assets/icons/carousel-right-arrow.svg";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export default function HomeSectionGallery() {
    const [availableProperties, setAvailableProperties] = useState([]);
    const url = process.env.REACT_APP_API_URL;
    const sliderRef = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onShowMoreClick = () => {
        navigate(`/about-us`);
    }

    const onShowAllClick = () => {
        navigate(`/properties`);
    }

    const onPropertyClick = (id) => {
        navigate(`/property/${id}`)
    }

    // Fetch properties
    useEffect(() => {
        const fetchProperties = async () => {
            const queryParams = new URLSearchParams({
                page: 1,
                limit: 6,
                featuredOnWebsite: true,
            });
            const response = await fetch(`${url}/public-apis/fetchProperties?${queryParams.toString()}`);
            const resData = await response.json();
            setAvailableProperties(resData);
        };
        fetchProperties().then();
    }, [url]);

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        rows: 2,
        slidesPerRow: 3,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 1,
                    slidesPerRow: 1,
                    infinite: true,
                }
            },
        ]
    };

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <div className="bg-primary flex flex-col relative">
            <div className="hidden md:hidden absolute right-0">
                <img src={logoElement} alt="Logo element"/>
            </div>
            <motion.div
                initial={{ x: "-100%", opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
                viewport={{ once: true}}
                className="mt-[93px] px-[40px] md:px-2 lg:px-[152px] 2x:px-[304px] md:mt-[220px]">
                <h1 className="h1-secondary !font-extralight">
                    DOWNTOWN
                </h1>
                <h1 className="h1-primary pe-24 mt-[20px]">
                    {t('home.gallery.title_1_1')} <i>{t('home.gallery.title_1_2')}</i> {t('home.gallery.title_1_3')}
                </h1>
                <div className="grid grid-cols-1 gap-y-10 mt-[41px] md:gap-y-0 md:grid-cols-2 md:gap-x-4">
                    <p className="text-left">
                        {t('home.gallery.text_1')}
                    </p>
                    <p className="text-left">
                        {t('home.gallery.text_2')}
                    </p>
                </div>
                <button className="btn-form mt-[60px] w-full md:w-[211px] ps-[42px] md:ps-0" onClick={onShowMoreClick}>
                    <div className="flex justify-between">
                        <span>{t('home.gallery.moreInfo')}</span>
                        <img src={searchIcon} alt="search"/>
                    </div>
                </button>
                <div className="flex items-end justify-between mt-[195px] md:mt-[221.5px] px-[10px] md:px-0">
                    <motion.h3
                        initial={{ x: "-100%", opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5, ease: "easeOut" }}
                        viewport={{ once: true}}
                        className="h3 text-center md:text-left">
                        {t('home.gallery.text_3_1')} <i>{t('home.gallery.text_3_2')}</i>
                    </motion.h3>
                    <button className="hidden md:block btn-form w-1/5 mb-2" onClick={onShowAllClick}>
                        <div className="flex justify-between">
                            <span>{t('home.gallery.showAll')}</span>
                            <img src={searchIcon} alt="search"/>
                        </div>
                    </button>
                </div>
            </motion.div>

            <div className="mt-[30px] md:mt-[90px]">
                <Slider {...settings} ref={sliderRef}>
                    {availableProperties?.docs?.map((item, index) => {
                        const photo = item.photos[0]
                        const isFirst = index % 3 === 0;
                        const isLast = (index + 1) % 3 === 0;
                        return (
                            <div key={index}
                                 className={`relative group md:p-[15px] cursor-pointer ${isFirst ? "md:ps-0" : ""} ${isLast ? "md:pe-0" : ""}`}
                                 onClick={() => onPropertyClick(item.id)}>
                                <img src={photo.path}
                                     alt={`item-${index}`}
                                     className="w-full h-[560px] md:h-[300px] lg:h-[500px] 2xl:h-[600px] object-cover"
                                />
                                <div
                                    className={`md:hidden md:group-hover:block absolute top-0 left-0 inset-0 bg-gradient-to-b from-[#040506a6] via-transparent to-[#040506a6] md:m-[15px] ${isFirst ? "md:ms-0" : ""} ${isLast ? "md:me-0" : ""}`}
                                />
                                <h6 className="h6-primary md:hidden md:group-hover:block absolute top-[32px] right-[40px]">Rif. {item.reference}</h6>
                                <div
                                    className="md:hidden md:group-hover:block absolute bottom-[34px] left-[40px] flex flex-col">
                                    <span className={'font-serifDisplay text-[35px] text-lightGray capitalize'}>
                                        {t(`property.typologies.${item.propertyTypology}`)}
                                    </span>
                                    <h6 className="h6-primary">
                                        {item.city ? `${item.city} -` : ''} {item?.province}
                                    </h6>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
            <div className="md:hidden mt-10">
                <div className="flex justify-center">
                    <button
                        onClick={prevSlide}
                        className="me-[10px]"
                    >
                        <img src={carouselLeftArrow} alt="prevSlide"/>
                    </button>
                    <button
                        onClick={nextSlide}
                    >
                        <img src={carouselRightArrow} alt="nextSlide"/>
                    </button>
                </div>
            </div>
            <div className="md:hidden mt-[60px] w-full px-10">
                <div className="flex justify-center pb-[100px] md:pb-[19px] w-full">
                    <button className="btn-form w-full">
                        <div className="flex justify-between">
                            <span className="ms-[42px] md:ms-[100px]">{t('home.gallery.showAll')}</span>
                            <img src={searchIcon} alt="search"/>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}
