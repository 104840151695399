import uploadIcon from '../assets/icons/upload-icon.svg';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function SidebarMenu ({ isOpen, onClose }) {
    const {t} = useTranslation();

    return (
        <div
            className={`fixed z-10 top-0 right-0 h-full w-full bg-gray-800 text-white transform ${
                isOpen ? 'translate-x-0' : 'translate-x-full'
            } transition-transform duration-300 px-10 bg-primary pt-[90px]`}
        >
            <div className="flex justify-end pt-1">
                <button
                    onClick={onClose}>
                    <img className={"rotate-45"} src={uploadIcon} alt="close"/>
                </button>
            </div>
            <div className="mt-[36px] text-center grid grid-cols-1 gap-y-[53px]">
                <Link
                    to="/about-us"
                    className={'sidebar-link'}
                    onClick={onClose}
                >
                    {t('header.company')}
                </Link>
                <Link
                    to="/properties"
                    className={'sidebar-link'}
                    onClick={onClose}
                >
                    {t('header.property')}
                </Link>
                <Link
                    to="/off-market-properties"
                    className={'sidebar-link'}
                    onClick={onClose}
                >
                    {t('header.offMarketProperty')}
                </Link>
                <Link
                    to="/property-finding"
                    className={'sidebar-link'}
                    onClick={onClose}
                >
                    {t('header.propertyFinding')}
                </Link>
                <Link
                    to="/contacts"
                    className={'sidebar-link'}
                    onClick={onClose}
                >
                    {t('header.contacts')}
                </Link>
                <Link
                    to="/downtown_posizioni_aperte"
                    className={'sidebar-link'}
                    onClick={onClose}
                >
                    {t('header.workWithUs')}
                </Link>
            </div>
        </div>
    )
}