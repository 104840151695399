import PropertyImage from "./PropertyImage";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function PropertiesListMobile({properties}) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    if (!properties || properties.length === 0) {
        return <h4 className="h4-secondary">{t('properties.noProperty')}</h4>;
    }

    const onPropertyClick = (id) => {
        navigate(`/property/${id}`)
    }

    return (
        <div className="grid grid-cols-1">
            {properties.map((property, index) => (
                <div
                    key={index}
                    className="flex flex-col items-center mb-20"
                    onClick={() => onPropertyClick(property.id)}
                >
                    <PropertyImage
                        photos={property.photos}
                        alt={`Photo for ${property.reference}`}
                    />
                    <span
                        className="font-raleway text-left text-[18px] tracking-[1.8px] text-gray w-full pb-10 uppercase">
                            Ref: {property.reference}
                    </span>
                    <span className={'font-serifDisplay text-left text-[30px] text-lightGray pb-10 capitalize w-full'}>
                        {t(`property.typologies.${property.propertyTypology}`)}
                    </span>
                    <span className="font-raleway text-left uppercase text-gray font-light tracking-[1.8px] w-full pb-10">
                            {property.price ? `${property.price.toLocaleString('it-IT')} €` : ''}
                    </span>
                    <div className={'flex self-start'}>
                        <span
                            className="font-raleway text-left uppercase text-gray font-light tracking-[1.8px] w-full pb-10">
                            {property.city ?? ''}
                        </span>
                        <span
                            className="font-raleway text-left uppercase text-gray font-light tracking-[1.8px] w-full pb-10">
                            {(property.city && property.province) ? '\u00a0-\u00a0' : ''}
                        </span>
                        <span
                            className="font-raleway text-left uppercase text-gray font-light tracking-[1.8px] w-full pb-10">
                            {property.province ?? ''}
                        </span>
                    </div>
                    <span
                        className="text-left font-raleway text-gray font-light tracking-[1.8px] w-full">{property.area ? `${property.area.toLocaleString('it-IT')} mq` : ''}
                    </span>
                </div>
            ))}
        </div>
    )
}