import {useCallback, useEffect, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import OffMarketSearchForm from "../components/OffMarketSearchForm";
import OffMarketPropertiesList from "../components/OffMarketPropertiesList";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

export default function OffMarketProperties() {
    const [availableProperties, setAvailableProperties] = useState([]);
    const hasMore = useRef(true);
    const currentPage = useRef(1);
    const url = process.env.REACT_APP_API_URL;
    const [searchParams] = useSearchParams();
    const {t} = useTranslation();

    const [province, setProvince] = useState('');
    const [typology, setTypology] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [minPrice, setMinPrice] = useState('');

    // Function to fetch properties
    const fetchProperties = useCallback(async (page, params = {}, reset = false) => {
        const filteredParams = Object.entries(params)
            .filter(([_, value]) => value != null && value !== '')
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        const queryParams = new URLSearchParams({
            page: page,
            limit: 5,
            ...filteredParams,
        });

        const response = await fetch(`${url}/public-apis/fetchProperties?${queryParams.toString()}`);
        const resData = await response.json();

        if (reset) {
            setAvailableProperties([...(resData.docs || [])])
        } else {
            setAvailableProperties((prev) => [
                ...prev,
                ...(resData.docs || [])
            ])
        }

        // Verifica se ci sono altre pagine
        if (currentPage.current >= resData.totalPages) {
            hasMore.current = false;
        }
    }, [url]);

    const loadMore = () => {
        currentPage.current += 1
        fetchProperties(currentPage.current, {
            province,
            typology,
            minPrice: minPrice ? parseFloat(minPrice.replace(/\./g, '').replace(',', '.')) : null,
            maxPrice: maxPrice ? parseFloat(maxPrice.replace(/\./g, '').replace(',', '.')) : null,
            status: 'offMarket'
        }).then();
    };


    // Fetch properties based on query params
    useEffect(() => {
        const typologyParam = searchParams.get('typology');
        const maxPriceParam = searchParams.get('maxPrice');
        const minPriceParam = searchParams.get('minPrice');
        const provinceParam = searchParams.get('province');

        if (typologyParam) setTypology(typologyParam);
        if (maxPriceParam) setMaxPrice(maxPriceParam);
        if (minPriceParam) setMinPrice(minPriceParam);
        if (provinceParam) setProvince(provinceParam);

        fetchProperties(1,{
            typology: typology,
            minPrice: minPrice ? parseFloat(minPrice.replace(/\./g, '').replace(',', '.')) : null,
            maxPrice: maxPrice ? parseFloat(maxPrice.replace(/\./g, '').replace(',', '.')) : null,
            province: province,
            status: 'offMarket'
        }).then();
    }, [fetchProperties, province, maxPrice, searchParams, typology, minPrice]);

    return (
        <div>
            <Helmet>
                <meta name="keywords" content="off market property"/>
                <title>Downtown – Off Market Property</title>
                <meta name="description"
                      content="Anche nel mercato del lusso ci sono proprietà ancora più esclusive di altre. Le abbiamo raccolte nella sezione Off Market Property. Scoprile."/>
            </Helmet>
            <div className="mt-[250px] md:mt-[283px] px-10 md:px-[40px] lg:px-[150px] 2xl:px-[300px] flex flex-col">
                <span
                    className="font-serifDisplay text-gray text-[40px] md:text-[60px] tracking-0 text-center md:text-left">
                Off - Market <i>Property</i>
                </span>
                <p className="mt-[60px]">
                    {t('offMarketProperty.text_1_1')}
                    <br/><br/>
                    {t('offMarketProperty.text_1_2')}
                    <br/><br/>
                    {t('offMarketProperty.text_1_3')}
                    <br/><br/>
                    {t('offMarketProperty.text_1_4')}
                    <br/><br/>
                    {t('offMarketProperty.text_1_5')}
                </p>
                <div className="mt-[130px] mb-20 md:mb-[200px]">
                    <OffMarketSearchForm onSearch={fetchProperties}/>
                </div>
                <InfiniteScroll
                    dataLength={availableProperties.length}
                    next={loadMore}
                    hasMore={hasMore.current}
                    loader={''}
                    endMessage={''}
                >
                    <OffMarketPropertiesList properties={availableProperties}/>
                </InfiniteScroll>
            </div>
        </div>
    )
}