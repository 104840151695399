import searchIcon from "../assets/icons/search-icon.svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function ThankYou() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleClick = () => {
        navigate('/');
    }

    return (
        <div className="px-[40px] md:ps-[15px] lg:ps-[300px] md:pe-[250px] lg:pe-[500px] pt-[180px] md:pt-[421px] pb-[80px] md:pb-[151px] flex flex-col items-start">
            <h1 className="h1-primary">
                {t('thankYou.title')}
            </h1>
            <p className="mt-[73px]">
                {t('thankYou.text')}
            </p>
            <button type="submit" className="btn-form mt-20 w-full md:w-1/2 ps-[42px] md:ps-0" onClick={handleClick}>
                <div className="flex justify-between">
                    <span className="me-8">
                        {t('thankYou.backToHome')}
                    </span>
                    <img src={searchIcon} alt="search"/>
                </div>
            </button>
        </div>
    )
}