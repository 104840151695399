import ContactForm from "./ContactForm";
import {useTranslation} from "react-i18next";

export default function PropertySectionContact ({ reference }) {
    const {t} = useTranslation();
    return(
        <div className="px-10 md:px-[150px] lg:px-[298px] mt-[81px] md:mt-[120px] text-center">
            <h4 className="h4-primary text-center">
                {t('properties.contact')}
            </h4>
            <div className="mt-[60px] mb-[71px]">
                <ContactForm subject={`Richiesta informazione immobile - Rif: ${reference}`} />
            </div>
        </div>
    )
}
