import ContactForm from "../components/ContactForm";
import { motion } from "motion/react"
import {useTranslation} from "react-i18next";

export default function Contacts() {
    const {t} = useTranslation();
    return (
        <div className="flex flex-col items-center text-center mt-[200px] md:mt-[283px] mb-[115px] px-10 md:px-0">
            <motion.div
                initial={{x: "-100%", opacity: 0}}
                whileInView={{x: 0, opacity: 1}}
                transition={{duration: 2, ease: "easeOut"}}
                viewport={{once: true}}>
                <h1 className="h1-primary">
                    {t('contacts.title_1_1')}<br/>{t('contacts.title_1_2')} <i>{t('contacts.title_1_3')}</i>
                </h1>
                <p className="mt-[60px] mb-[125px] px-10 md:px-[150px] lg:px-[301px]">
                    {t('contacts.text_1_1')}<br/><br/>{t('contacts.text_1_2')}
                </p>
            </motion.div>
            <motion.div
                initial={{x: "100%", opacity: 0}}
                whileInView={{x: 0, opacity: 1}}
                transition={{duration: 2, ease: "easeOut"}}
                viewport={{once: true}}>
               <ContactForm subject={'Contatto generale'}/>
            </motion.div>
        </div>
    )
}