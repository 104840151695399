import { motion } from "motion/react"

import rightArrow from "../assets/icons/right-arrow.svg"
import searchIcon from "../assets/icons/search-icon.svg";
import lineaVerticale from "../assets/icons/linea-verticale.svg"
import thisIsDownTown from "../assets/images/questa-e-downtown.jpg"
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


export default function HomeSectionAboutUs () {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onMoreInfoClick = () => {
        navigate(`/about-us`);
    }
    return (
        <div
            className="h-full bg-cover bg-center bg-no-repeat pt-[120px] md:pt-[159px] px-[40px] md:px-2 lg:px-[152px] 2xl:px-[310px] relative"
            style={{backgroundImage: `url('${thisIsDownTown}')`}}
        >
            <div className="absolute inset-0 bg-primary opacity-90"></div>
            <div className="flex flex-col relative">
                <motion.h2
                    initial={{ x: "-100%", opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 }}
                    viewport={{ once: true}}
                    className="h2-secondary text-center md:text-left">
                    {t('home.aboutUs.personalApproach')} <br className="md:hidden"/>{t('home.aboutUs.globalVision')}
                </motion.h2>
                <div className="flex flex-col">
                    <motion.h2
                        initial={{ x: "-100%", opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5, ease: "easeOut" }}
                        viewport={{ once: true}}
                        className="h1-primary mt-5 text-center md:text-left">
                        {t('home.aboutUs.thisIs')} <i>Downtown</i>
                    </motion.h2>
                    <motion.img
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5, ease: "easeOut", delay: 1 }}
                        className={'hidden md:block ms-[180px] mt-2'} width="1" height="30" src={lineaVerticale}
                         alt="Linea verticale"/>
                </div>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeOut", delay: 1 }}
                    className="md:hidden flex flex-col items-center mt-6 mb-10">
                    <img width="1" height="30" src={lineaVerticale} alt="Linea verticale"/>
                </motion.div>

                <div
                    className="flex flex-col md:flex-row md:mt-[40px] md:mb-[128.5px] md:justify-between md:space-x-[200px]">
                    <motion.div
                        initial={{ x: "-100%", opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5, ease: "easeOut", delay: 1.5 }}
                        viewport={{ once: true}}
                        className="flex flex-col md:flex-row items-center md:items-start">
                        <p className="text-center md:text-left">
                            {t('home.aboutUs.text_1')}
                        </p>
                        <img src={rightArrow} alt="Right arrow"
                             className="ms-0 md:ms-[20px] rotate-90 md:rotate-0 mt-[41px] md:mt-1 mb-[105px] md:mb-auto"/>
                    </motion.div>
                    <div className="flex flex-col">
                        <motion.p
                            initial={{ x: "100%", opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ duration: 0.8, ease: "easeOut", delay: 2 }}
                            viewport={{ once: true}}
                            className="text-center md:text-left">
                            {t('home.aboutUs.text_2_1')}
                            <br/><br/>
                            {t('home.aboutUs.text_2_2')}
                            <br/><br/>
                            {t('home.aboutUs.text_2_3')}
                        </motion.p>
                        <div
                            className="flex md:justify-end w-full pb-5 md:pb-0 mb-[50px] md:mb-0">
                            <motion.button
                                initial={{ y: "100%", opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.8, ease: "easeOut", delay: 2.5 }}
                                viewport={{ once: true}}
                                className="btn-form mt-[60px] w-full md:w-1/3 text-right"
                                onClick={onMoreInfoClick}
                            >
                                <div className="flex justify-between">
                                    <span>{t('home.aboutUs.moreInfo')}</span>
                                    <img src={searchIcon} alt="search"/>
                                </div>
                            </motion.button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
