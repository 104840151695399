import {useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import imgFallback from "../assets/images/image-fallback.webp";
import shareIcon from "../assets/icons/share-icon.svg";
import PropertySectionTab from "../components/PropertySectionTab";
import PropertySectionGallery from "../components/PropertySectionGallery";
import PropertySectionContact from "../components/PropertySectionContact";
import Slider from "react-slick";
import carouselLeftArrow from "../assets/icons/carousel-left-arrow.svg";
import carouselRightArrow from "../assets/icons/carousel-right-arrow.svg";
import {useTranslation} from "react-i18next";

export default function Property() {
    const {propertyId} = useParams();
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);
    const url = process.env.REACT_APP_API_URL;
    const sliderRef = useRef(null);
    const { t } = useTranslation();

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                url: window.location.href,
            })
                .then()
                .catch((error) => console.error('Errore durante la condivisione:', error));
        } else {
            console.log('La condivisione non è supportata in questo browser.');
        }
    };

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    useEffect(() => {
        async function getProperty() {
            const response = await fetch(`${url}/public-apis/property/${propertyId}`);
            const resData = await response.json();
            setProperty(resData);
            setLoading(false);
        }
        getProperty();
    }, [propertyId, url]);

    const photoBgSrc = property?.photos?.length > 0 ? property.photos[0].path : imgFallback;

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <section
                className="hidden md:block relative w-full max-w-[1920px] mx-auto"
                style={{aspectRatio: '16 / 9'}}
            >
                <div
                    className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
                    style={{
                        backgroundImage: `
                         linear-gradient(0deg, #1A1A1A00 80%, #040506 98%),
                         linear-gradient(180deg, #1A1A1A00 0%, #040506 100%),
                    url(${photoBgSrc})`
                    }}
                ></div>
                <div className="box relative h-full flex items-end">
                    <div className="w-full px-[198px]">
                        <div className="flex justify-between items-end">
                            <div className="flex flex-col">
                                <span className={'font-serifDisplay text-[45px] text-lightGray capitalize'}>
                                        {t(`property.typologies.${property?.propertyTypology}`)}
                                </span>
                                <span
                                    className="font-raleway text-left text-[18px] tracking-[1.8px] text-gray uppercase">
                                    {property?.city}
                                </span>
                            </div>
                            <div className="flex">
                                <span
                                    className={'font-raleway text-left text-[18px] tracking-[1.8px] text-gray uppercase flex'}>{property?.reference}</span>
                                <button
                                    className="font-raleway text-left text-[18px] tracking-[1.8px] text-gray uppercase flex"
                                    onClick={handleShare}>
                                    <img className="ms-4 mt-1" src={shareIcon} alt="share"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="md:hidden mb-[122px]">
                <Slider ref={sliderRef} {...settings}>
                    {property?.photos.map((item, index) => {
                        return (
                            <div key={index}>
                                <img
                                    src={item.path}
                                    alt={`item-${index}`}
                                    className="w-full h-screen object-cover"
                                />
                            </div>
                        );
                    })}
                </Slider>
                <div
                    className="md:hidden absolute top-0 left-0 h-screen inset-0 bg-gradient-to-b from-[#040506a6] via-transparent to-[#040506]"
                />
            </section>
            <div className="md:hidden absolute right-10 bottom-[70px]">
                <button
                    onClick={prevSlide}
                    className="me-[10px]"
                >
                    <img src={carouselLeftArrow} alt="prevSlide"/>
                </button>
                <button
                    onClick={nextSlide}
                >
                    <img src={carouselRightArrow} alt="nextSlide"/>
                </button>
            </div>
            <PropertySectionTab
                description={property?.propertyDescription}
                area={property?.area?.toLocaleString('it-IT')}
                outdoorArea={property?.outdoorArea?.toLocaleString('it-IT')}
                price={property?.price?.toLocaleString('it-IT')}
                city={property?.city}
                reference={property?.reference}
                floorPlans={property?.floorPlans}
                propertyTypology={property?.propertyTypology}
            />
            <PropertySectionGallery photos={property?.photos}/>
            <PropertySectionContact reference={property?.reference} />
        </div>
    )
}
